<template>
    <div class="card box widget-social pt-2 pb-2" :class="[alarmStatus,componentStyle]">
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="energy" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagEnergy"
            @show="focusElement"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Energiespar Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('energy')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel>
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataEnergy('energyEnable').value ? '' : 'line-through'">Allgemein</span>
                            </div>
                        </template>
                        <Panel header="Programmeinstellung">
                            <template #icons>
                                <i class="fi fi-rr-power bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <div class="formgrid grid p-1">
                                        <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                            Das Energiesparprogramm (Wärmeschirm) sorgt dafür, dass Wärme im Gewächshaus gespeichert wird, bzw. den Schirm nicht öffnet, falls die Globalstrahlung nicht ausreichend stark vorhanden ist.
                                        </InlineMessage>
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataEnergy('energyEnable').value" /><span class="ml-2">{{ this.getDiagDataEnergy('energyEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Programmfreigabe" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-time-quarter-past bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataEnergy('energyZeitVonMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataEnergy('energyZeitVonMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataEnergy('energyZeitVon').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="energyZeitVon" ref="energyZeitVon" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataEnergy('energyZeitVon').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="energyZeitVon" class="mr-2">{{ this.getDiagDataEnergy('energyZeitVon').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataEnergy('energyZeitBisMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataEnergy('energyZeitBisMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataEnergy('energyZeitBis').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="energyZeitBis" ref="energyZeitBis" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataEnergy('energyZeitBis').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="energyZeitBis" class="mr-2">{{ this.getDiagDataEnergy('energyZeitBis').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Lüftungsspalt Energie" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-arrows-h-copy bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid pb-4">
                                <div class="field col">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataEnergy('stellungLueftEnergy').value" /><span class="ml-2">{{ this.getDiagDataEnergy('stellungLueftEnergy').label }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid" v-if="this.getDiagDataEnergy('stellungLueftEnergy').value">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energySpaltMin" ref="energySpaltMin" :min="0" :max="100"
                                            v-model="this.getDiagDataEnergy('energySpaltMin').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energySpaltMin').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energySpaltMin" class="mr-2">{{ this.getDiagDataEnergy('energySpaltMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energySpaltMax" ref="energySpaltMax" :min="0" :max="100"
                                            v-model="this.getDiagDataEnergy('energySpaltMax').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energySpaltMax').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energySpaltMax" class="mr-2">{{ this.getDiagDataEnergy('energySpaltMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Schritte">
                        <Panel header="Schritte - Schließen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-paw bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyCloseStepSizeFirst" ref="energyCloseStepSizeFirst" :min="0" :max="100"
                                            v-model="this.getDiagDataEnergy('energyCloseStepSizeFirst').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyCloseStepSizeFirst').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyCloseStepSizeFirst" class="mr-2">{{ this.getDiagDataEnergy('energyCloseStepSizeFirst').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyCloseStepSize" ref="energyCloseStepSize" :min="0" :max="100"
                                            v-model="this.getDiagDataEnergy('energyCloseStepSize').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyCloseStepSize').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyCloseStepSize" class="mr-2">{{ this.getDiagDataEnergy('energyCloseStepSize').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid mt-3">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyCloseStepCount" ref="energyCloseStepCount" :min="0" :max="100"
                                            v-model="this.getDiagDataEnergy('energyCloseStepCount').value"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyCloseStepCount" class="mr-2">{{ this.getDiagDataEnergy('energyCloseStepCount').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">

                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-12">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="energyCloseDelayFirst" ref="energyCloseDelayFirst" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataEnergy('energyCloseDelayFirst').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyCloseDelayFirst" class="mr-2">{{ this.getDiagDataEnergy('energyCloseDelayFirst').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-12">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="energyCloseStepInterval" ref="energyCloseStepInterval" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataEnergy('energyCloseStepInterval').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyCloseStepInterval" class="mr-2">{{ this.getDiagDataEnergy('energyCloseStepInterval').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Schritte - Öffnen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-paw bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyOpenStepSizeFirst" ref="energyOpenStepSizeFirst" :min="0" :max="100"
                                            v-model="this.getDiagDataEnergy('energyOpenStepSizeFirst').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyOpenStepSizeFirst').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyOpenStepSizeFirst" class="mr-2">{{ this.getDiagDataEnergy('energyOpenStepSizeFirst').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyOpenStepSize" ref="energyOpenStepSize" :min="0" :max="100"
                                            v-model="this.getDiagDataEnergy('energyOpenStepSize').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyOpenStepSize').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyOpenStepSize" class="mr-2">{{ this.getDiagDataEnergy('energyOpenStepSize').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid mt-3">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyOpenStepCount" ref="energyOpenStepCount" :min="0" :max="100"
                                            v-model="this.getDiagDataEnergy('energyOpenStepCount').value"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyOpenStepCount" class="mr-2">{{ this.getDiagDataEnergy('energyOpenStepCount').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">

                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-12">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="energyOpenDelayFirst" ref="energyOpenDelayFirst" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataEnergy('energyOpenDelayFirst').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyOpenDelayFirst" class="mr-2">{{ this.getDiagDataEnergy('energyOpenDelayFirst').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-12">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="energyOpenStepInterval" ref="energyOpenStepInterval" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataEnergy('energyOpenStepInterval').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyOpenStepInterval" class="mr-2">{{ this.getDiagDataEnergy('energyOpenStepInterval').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataEnergy('energyRtEnable').value ? '' : 'line-through'">Raumtemperatur</span>
                            </div>
                        </template>
                        <Panel header="Programmeinstellung">
                            <template #icons>
                                <i class="fi fi-rr-power bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12 mt-3">
                                    <div class="formgrid grid p-1">
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataEnergy('energyRtEnable').value" /><span class="ml-2">{{ this.getDiagDataEnergy('energyRtEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Einstellwerte" class="mt-3" v-if="this.getDiagDataEnergy('energyRtEnable').value">
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyRtValue" ref="energyRtValue" :min="-30" :max="100"
                                            v-model="this.getDiagDataEnergy('energyRtValue').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyRtValue').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyRtValue" class="mr-2">{{ this.getDiagDataEnergy('energyRtValue').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyRtDiff" ref="energyRtDiff" :min="-30" :max="100"
                                            v-model="this.getDiagDataEnergy('energyRtDiff').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyRtDiff').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyRtDiff" class="mr-2">{{ this.getDiagDataEnergy('energyRtDiff').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid mt-3">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyRtRadiation" ref="energyRtRadiation" :min="0" :max="1500"
                                            v-model="this.getDiagDataEnergy('energyRtRadiation').value"
                                            :suffix="` ${this.getDiagDataEnergy('energyRtRadiation').unit}`"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyRtRadiation" class="mr-2">{{ this.getDiagDataEnergy('energyRtRadiation').label }}</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataEnergy('energyRtRadiation').value, 'toKlx') }} klx</span>
                                </div>
                                <div class="field col-6">

                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataEnergy('energyAtEnable').value ? '' : 'line-through'">Außentemperatur</span>
                            </div>
                        </template>
                        <Panel header="Programmeinstellung">
                            <template #icons>
                                <i class="fi fi-rr-power bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12 mt-3">
                                    <div class="formgrid grid p-1">
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataEnergy('energyAtEnable').value" /><span class="ml-2">{{ this.getDiagDataEnergy('energyAtEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Einstellwerte" class="mt-3" v-if="this.getDiagDataEnergy('energyAtEnable').value">
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyAtValue" ref="energyAtValue" :min="-30" :max="100"
                                            v-model="this.getDiagDataEnergy('energyAtValue').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyAtValue').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyAtValue" class="mr-2">{{ this.getDiagDataEnergy('energyAtValue').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyAtDiff" ref="energyAtDiff" :min="-30" :max="100"
                                            v-model="this.getDiagDataEnergy('energyAtDiff').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyAtDiff').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyAtDiff" class="mr-2">{{ this.getDiagDataEnergy('energyAtDiff').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid mt-3">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyAtRadiation" ref="energyAtRadiation" :min="0" :max="1500"
                                            v-model="this.getDiagDataEnergy('energyAtRadiation').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyAtRadiation').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyAtRadiation" class="mr-2">{{ this.getDiagDataEnergy('energyAtRadiation').label }}</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataEnergy('energyAtRadiation').value, 'toKlx') }} klx</span>
                                </div>
                                <div class="field col-6">

                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataEnergy('energyRadEnable').value ? '' : 'line-through'">Globalstrahlung</span>
                            </div>
                        </template>
                        <Panel header="Programmeinstellung">
                            <template #icons>
                                <i class="fi fi-rr-power bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12 mt-3">
                                    <div class="formgrid grid p-1">
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataEnergy('energyRadEnable').value" /><span class="ml-2">{{ this.getDiagDataEnergy('energyRadEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Einstellwerte" class="mt-3" v-if="this.getDiagDataEnergy('energyRadEnable').value">
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyStValue" ref="energyStValue" :min="0" :max="1500"
                                            v-model="this.getDiagDataEnergy('energyStValue').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyStValue').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyStValue" class="mr-2">{{ this.getDiagDataEnergy('energyStValue').label }}</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataEnergy('energyStValue').value, 'toKlx') }} klx</span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyStDiff" ref="energyStDiff" :min="-1500" :max="1500"
                                            v-model="this.getDiagDataEnergy('energyStDiff').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyStDiff').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyStDiff" class="mr-2">{{ this.getDiagDataEnergy('energyStDiff').label }}</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataEnergy('energyStDiff').value, 'toKlx') }} klx</span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataEnergy('energyIrEnable').value ? '' : 'line-through'">Wärmestrahlung IR</span>
                            </div>
                        </template>
                        <Panel header="Programmeinstellung">
                            <template #icons>
                                <i class="fi fi-rr-power bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12 mt-3">
                                    <div class="formgrid grid p-1">
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataEnergy('energyIrEnable').value" /><span class="ml-2">{{ this.getDiagDataEnergy('energyIrEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Einstellwerte" class="mt-3" v-if="this.getDiagDataEnergy('energyIrEnable').value">
                            <template #header>
                                <p>Einstellwerte</p>
                                <p class="fg-yellow">{{ this.getModuleValueStr('OUT_ThermalOutgoing') }}</p>
                            </template>
                            <template #icons>
                                <i class="fi fi-rr-arrows-h-copy bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyIrValue" ref="energyIrValue" :min="-250" :max="250"
                                            v-model="this.getDiagDataEnergy('energyIrValue').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyIrValue').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyIrValue" class="mr-2">{{ this.getDiagDataEnergy('energyIrValue').label }}</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataEnergy('energyIrValue').value, 'toKlx') }} klx</span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyIrDiff" ref="energyIrDiff" :min="-250" :max="250"
                                            v-model="this.getDiagDataEnergy('energyIrDiff').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyIrDiff').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyIrDiff" class="mr-2">{{ this.getDiagDataEnergy('energyIrDiff').label }}</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataEnergy('energyIrDiff').value, 'toKlx') }} klx</span>
                                </div>
                            </div>
                            <div class="formgrid grid mt-3">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="energyIrRadiation" ref="energyIrRadiation" :min="0" :max="1500"
                                            v-model="this.getDiagDataEnergy('energyIrRadiation').value"
                                            :suffix="' ' + this.getDiagDataEnergy('energyIrRadiation').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="energyIrRadiation" class="mr-2">{{ this.getDiagDataEnergy('energyIrRadiation').label }}</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataEnergy('energyIrRadiation').value, 'toKlx') }} klx</span>
                                </div>
                                <div class="field col-6">

                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleEnergy" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('energy')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <OverlayPanel class="param-overlay" ref="shading" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagShading"
            @show="focusElement"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Schattierung Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('shading')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel>
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataShading('shadingEnable').value ? '' : 'line-through'">Allgemein</span>
                            </div>
                        </template>
                        <Panel header="Programmeinstellung">
                            <template #icons>
                                <i class="fi fi-rr-power bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12 mt-3">
                                    <div class="formgrid grid p-1">
                                        <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                            Schirmstellung 0%=geöffnet / 100%=geschlossen
                                        </InlineMessage>
                                        <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                            Das Schattierungsprogramm (Schattierschirm) sorgt dafür, dass ab einer eingestellten Globalstrahlung (Bereich Start/Ende unter Lichtwerte) der Schirm schließt, um die Kultur vor zu viel Sonneneinstrahlung zu schützen.
                                        </InlineMessage>
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataShading('shadingEnable').value" /><span class="ml-2">{{ this.getDiagDataShading('shadingEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Programmfreigabe" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-time-quarter-past bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataShading('shadingZeitVonMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataShading('shadingZeitVonMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataShading('shadingZeitVon').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="shadingZeitVon" ref="shadingZeitVon" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataShading('shadingZeitVon').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="shadingZeitVon" class="mr-2">{{ this.getDiagDataShading('shadingZeitVon').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataShading('shadingZeitBisMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataShading('shadingZeitBisMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataShading('shadingZeitBis').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="shadingZeitBis" ref="shadingZeitBis" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataShading('shadingZeitBis').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="shadingZeitBis" class="mr-2">{{ this.getDiagDataShading('shadingZeitBis').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Lüftungsspalt Schattieren" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-arrows-h-copy bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid pb-4">
                                <div class="field col">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataShading('stellungLueft').value" /><span class="ml-2">{{ this.getDiagDataShading('stellungLueft').label }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid" v-if="this.getDiagDataShading('stellungLueft').value">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingSpaltMin" ref="shadingSpaltMin" :min="0" :max="100"
                                            v-model="this.getDiagDataShading('shadingSpaltMin').value"
                                            :suffix="' ' + this.getDiagDataShading('shadingSpaltMin').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingSpaltMin" class="mr-2">{{ this.getDiagDataShading('shadingSpaltMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingSpaltMax" ref="shadingSpaltMax" :min="0" :max="100"
                                            v-model="this.getDiagDataShading('shadingSpaltMax').value"
                                            :suffix="' ' + this.getDiagDataShading('shadingSpaltMax').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingSpaltMax" class="mr-2">{{ this.getDiagDataShading('shadingSpaltMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Lichtwerte">
                        <Panel header="Arbeitsbereich">
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingValueMin" ref="shadingValueMin" :min="0" :max="2000"
                                            v-model="this.getDiagDataShading('shadingValueMin').value"
                                            :suffix="' ' + this.getDiagDataShading('shadingValueMin').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingValueMin" class="mr-2">{{ this.getDiagDataShading('shadingValueMin').label }}</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataShading('shadingValueMin').value, 'toKlx') }} klx</span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingValueMax" ref="shadingValueMax" :min="0" :max="2000"
                                            v-model="this.getDiagDataShading('shadingValueMax').value"
                                            :suffix="' ' + this.getDiagDataShading('shadingValueMax').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingValueMax" class="mr-2">{{ this.getDiagDataShading('shadingValueMax').label }}</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataShading('shadingValueMax').value, 'toKlx') }} klx</span>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingDiff" ref="shadingDiff" :min="-1000" :max="1000"
                                            v-model="this.getDiagDataShading('shadingDiff').value"
                                            :suffix="' ' + this.getDiagDataShading('shadingDiff').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingDiff" class="mr-2">{{ this.getDiagDataShading('shadingDiff').label }}</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataShading('shadingDiff').value, 'toKlx') }} klx</span>
                                </div>
                                <div class="field col-6">

                                </div>
                            </div>
                        </Panel>
                        <Panel header="Verzögerungszeiten" class="mt-3">
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="shadingCloseDelay" ref="shadingCloseDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataShading('shadingCloseDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingCloseDelay" class="mr-2">{{ this.getDiagDataShading('shadingCloseDelay').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="shadingOpenDelay" ref="shadingOpenDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataShading('shadingOpenDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingOpenDelay" class="mr-2">{{ this.getDiagDataShading('shadingOpenDelay').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Serviceparameter" v-if="isMaster">
                        <Panel header="Schritte - Schließen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-paw bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingCloseStepSize" ref="shadingCloseStepSize" :min="0" :max="100"
                                            v-model="this.getDiagDataShading('shadingCloseStepSize').value"
                                            :suffix="' ' + this.getDiagDataShading('shadingCloseStepSize').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingCloseStepSize" class="mr-2">{{ this.getDiagDataShading('shadingCloseStepSize').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingCloseStepCount" ref="shadingCloseStepCount" :min="0" :max="100"
                                            v-model="this.getDiagDataShading('shadingCloseStepCount').value"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingCloseStepCount" class="mr-2">{{ this.getDiagDataShading('shadingCloseStepCount').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Schritte - Öffnen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-paw bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingOpenStepSize" ref="shadingOpenStepSize" :min="0" :max="100"
                                            v-model="this.getDiagDataShading('shadingOpenStepSize').value"
                                            :suffix="' ' + this.getDiagDataShading('shadingOpenStepSize').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingOpenStepSize" class="mr-2">{{ this.getDiagDataShading('shadingOpenStepSize').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingOpenStepCount" ref="shadingOpenStepCount" :min="0" :max="100"
                                            v-model="this.getDiagDataShading('shadingOpenStepCount').value"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingOpenStepCount" class="mr-2">{{ this.getDiagDataShading('shadingOpenStepCount').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleShading" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('shading')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <OverlayPanel class="param-overlay" ref="minmax" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagMinMax"
            @show="focusElement"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ MIN/MAX Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('minmax')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel header="Min/Max">
                        <Panel header="Absolute Stellungen im Regelbetrieb">
                            <template #icons>
                                <i class="fi fi-rr-arrow-to-bottom bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="stellungMin" ref="stellungMin" :min="0" :max="100"
                                            v-model="this.getDiagDataMinMax('stellungMin').value"
                                            :suffix="' ' + this.getDiagDataMinMax('stellungMin').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="stellungMin" class="mr-2">{{ this.getDiagDataMinMax('stellungMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="stellungMax" ref="stellungMax" :min="0" :max="100"
                                            v-model="this.getDiagDataMinMax('stellungMax').value"
                                            :suffix="' ' + this.getDiagDataMinMax('stellungMax').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="stellungMax" class="mr-2">{{ this.getDiagDataMinMax('stellungMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Feuchtespalt-Einstellungen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-arrow-to-top bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="stellungFeuchteMin" ref="stellungFeuchteMin" :min="0" :max="100"
                                            v-model="this.getDiagDataMinMax('stellungFeuchteMin').value"
                                            :suffix="' ' + this.getDiagDataMinMax('stellungFeuchteMin').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungFeuchteMin" class="mr-2">{{ this.getDiagDataMinMax('stellungFeuchteMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="stellungFeuchteMax" ref="stellungFeuchteMax" :min="0" :max="100"
                                            v-model="this.getDiagDataMinMax('stellungFeuchteMax').value"
                                            :suffix="' ' + this.getDiagDataMinMax('stellungFeuchteMax').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungFeuchteMax" class="mr-2">{{ this.getDiagDataMinMax('stellungFeuchteMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <!-- <div class="field">
                    <div class="grid justify-content-center">
                        <h5>{{ currentGrp.label }}</h5>
                    </div>
                </div> -->
                    <TabPanel header="Zeitbegrenzungen">
                        <Panel header="Zeitbegrenzung MIN-Stellung (übersteuert MIN Regelbetrieb)">
                            <template #icons>
                                <i class="fi fi-rr-time-quarter-past bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataMinMax('stellungMinZeitVonMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataMinMax('stellungMinZeitVonMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataMinMax('stellungMinZeitVon').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="minTimeStart" ref="minValueTimeStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('stellungMinZeitVon').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="minValueTimeStart" class="mr-2">MIN-Zeitbegrenzung von</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataMinMax('stellungMinZeitBisMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataMinMax('stellungMinZeitBisMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataMinMax('stellungMinZeitBis').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="minTimeEnd" ref="minValueTimeEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('stellungMinZeitBis').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeEnd" class="mr-2">MIN-Zeitbegrenzung bis</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field col">
                                <div class="formgrid grid p-1">
                                    <span class="p-float-label">
                                        <InputNumber id="stellungMinZeitSchirm" ref="stellungMinZeitSchirm" :min="0" :max="100"
                                            v-model="this.getDiagDataMinMax('stellungMinZeitSchirm').value" mode="decimal"
                                            :suffix="' ' + this.getDiagDataMinMax('stellungMinZeitSchirm').unit"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungMinZeitSchirm" class="mr-2">MIN-Zeitbegrenzung</label>
                                    </span>
                                </div>
                            </div>
                            <div class="field col-12 mt-3">
                                <div class="formgrid grid p-1">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataMinMax('stellungMinZeit').value" /><span class="ml-2">MIN-Begrenzung aktivieren</span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Zeitbegrenzung MAX-Stellung (übersteuert MAX Regelbetrieb)" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-time-quarter-to bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataMinMax('stellungMaxZeitVonMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataMinMax('stellungMaxZeitVonMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataMinMax('stellungMaxZeitVon').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="stellungMaxZeitVon" ref="stellungMaxZeitVon" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('stellungMaxZeitVon').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="stellungMaxZeitVon" class="mr-2">MAX-Zeitbegrenzung von</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataMinMax('stellungMaxZeitBisMode').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataMinMax('stellungMaxZeitBisMode').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataMinMax('stellungMaxZeitBis').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="stellungMaxZeitBis" ref="stellungMaxZeitBis" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('stellungMaxZeitBis').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="stellungMaxZeitBis" class="mr-2">MAX-Zeitbegrenzung bis</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field col">
                                <div class="formgrid grid p-1">
                                    <div class="field">
                                        <span class="p-float-label">
                                            <InputNumber id="stellungMaxZeitSchirm" ref="stellungMaxZeitSchirm" :min="0" :max="100"
                                                v-model="this.getDiagDataMinMax('stellungMaxZeitSchirm').value" mode="decimal"
                                                :suffix="' ' + this.getDiagDataMinMax('stellungMaxZeitSchirm').unit"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="stellungMaxZeitSchirm" class="mr-2">MAX-Zeitbegrenzung</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="field col-12 mt-3">
                                <div class="formgrid grid p-1">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataMinMax('stellungMaxZeit').value" /><span class="ml-2">MAX-Begrenzung aktivieren</span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>                                           
                    <TabPanel header="Schutzfunktionen">
                        <Panel header="Verzögerungszeiten">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Diese Zeiten legen fest, wie lange bei einem zutreffendem Ereignis gewartet werden soll, bis die Schutzfunktion aktiv schaltet bzw. wieder ausschaltet.`"></i>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>

                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="safetyDelayOn" ref="safetyDelayOn" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataMinMax('safetyDelayOn').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="safetyDelayOn" class="mr-2">{{ this.getDiagDataMinMax('safetyDelayOn').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="safetyDelayOff" ref="safetyDelayOff" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataMinMax('safetyDelayOff').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="safetyDelayOff" class="mr-2">{{ this.getDiagDataMinMax('safetyDelayOff').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Schutz bei Regen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Der Regenschutz zwingt den Schirm bei aktiviertem Hauptschalter in die Regenposition, falls die Wetterstation Regen registriert.`"></i>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>

                            <div class="formgrid grid p-1">
                                <div class="field col-6 mt-0">
                                    <span class="p-float-label">
                                        <InputNumber id="safetyRainPosition" ref="safetyRainPosition" :min="0" :max="100"
                                            v-model="this.getDiagDataMinMax('safetyRainPosition').value" mode="decimal"
                                            :suffix="' ' + this.getDiagDataMinMax('safetyRainPosition').unit"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="safetyRainPosition" class="mr-2">{{ this.getDiagDataMinMax('safetyRainPosition').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6 mt-0">
                                    <div class="formgrid grid p-1">
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataMinMax('safetyRainEnable').value" /><span class="ml-2">{{ this.getDiagDataMinMax('safetyRainEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Schutz bei Sturm" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Der Sturmschutz zwingt den Schirm bei aktiviertem Hauptschalter in die Sturmposition, falls der Windsensor der Wetterstation mehr Wind anzeigt als der Schwellwert der Einstellung.`"></i>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>

                            <div class="formgrid grid p-1">
                                <div class="field col mt-0">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataMinMax('safetyWindEnable').value" /><span class="ml-2">{{ this.getDiagDataMinMax('safetyWindEnable').label }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <InputNumber id="safetyWindPosition" ref="safetyWindPosition" :min="0" :max="100"
                                            v-model="this.getDiagDataMinMax('safetyWindPosition').value" mode="decimal"
                                            :suffix="' ' + this.getDiagDataMinMax('safetyWindPosition').unit"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="safetyWindPosition" class="mr-2">{{ this.getDiagDataMinMax('safetyWindPosition').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <InputNumber id="safetyWindValue" ref="safetyWindValue" :min="0" :max="100"
                                            v-model="this.getDiagDataMinMax('safetyWindValue').value" mode="decimal"
                                            :suffix="' ' + this.getDiagDataMinMax('safetyWindValue').unit"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="safetyWindValue" class="mr-2">{{ this.getDiagDataMinMax('safetyWindValue').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Hitzeschutz" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Der Hitzeschutz zwingt den Schirm bei aktiviertem Hauptschalter in die Hitzeschutzposition, falls der Außentemperatursensor der Wetterstation mehr Temperatur anzeigt, als der Schwellwert der Einstellung. Ist ein Spalt größer als 0% eingestellt, wird 100% (geschlossen) MINUS Hitzeschutz-Spalt % = tatsächlich berechnete Schirmsollstellung gerechnet.`"></i>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>

                            <div class="formgrid grid p-1">
                                <div class="field col mt-0">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataMinMax('shadingAtEnable').value" /><span class="ml-2">{{ this.getDiagDataMinMax('shadingAtEnable').label }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingAtValue" ref="shadingAtValue" :min="-100" :max="100"
                                            v-model="this.getDiagDataMinMax('shadingAtValue').value" mode="decimal"
                                            :suffix="' ' + this.getDiagDataMinMax('shadingAtValue').unit"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingAtValue" class="mr-2">{{ this.getDiagDataMinMax('shadingAtValue').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingAtDiff" ref="shadingAtDiff" :min="-100" :max="100"
                                            v-model="this.getDiagDataMinMax('shadingAtDiff').value" mode="decimal"
                                            :suffix="' ' + this.getDiagDataMinMax('shadingAtDiff').unit"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingAtDiff" class="mr-2">{{ this.getDiagDataMinMax('shadingAtDiff').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingAtRadiation" ref="shadingAtRadiation" :min="0" :max="2500"
                                            v-model="this.getDiagDataMinMax('shadingAtRadiation').value" mode="decimal"
                                            :suffix="' ' + this.getDiagDataMinMax('shadingAtRadiation').unit"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingAtRadiation" class="mr-2">{{ this.getDiagDataMinMax('shadingAtRadiation').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <InputNumber id="shadingAtSpalt" ref="shadingAtSpalt" :min="0" :max="100"
                                            v-model="this.getDiagDataMinMax('shadingAtSpalt').value" mode="decimal"
                                            :suffix="' ' + this.getDiagDataMinMax('shadingAtSpalt').unit"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="shadingAtSpalt" class="mr-2">{{ this.getDiagDataMinMax('shadingAtSpalt').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleMinMax" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('minmax')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <OverlayPanel ref="misc" appendTo="body" @show="focusElement" :dismissable="false">
        </OverlayPanel>
        <Dialog
            v-model:visible="showDiagMisc"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
        >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i><span class="subtext mt-1">{{ moduleDepartment.key }} {{ moduleDepartment.label }} ⚡ Allgemeine Einstellungen</span></h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('misc')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView :scrollable="true">
                    <TabPanel header="Schirmregler">
                        <Panel header="Motor-Einstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-heat bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="laufzeit1" ref="laufzeit1" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('laufzeit1').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="laufzeit1" class="mr-2">Schirm Laufzeit</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Service" v-if="isMaster">
                        <Panel header="Schaltschwellen">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="schaltschwelleMin" ref="schaltschwelleMin" :min="0" :max="100"
                                            v-model="getDiagDataCfg('schaltschwelleMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('schaltschwelleMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="schaltschwelleMin" class="mr-2">{{ getDiagDataCfg('schaltschwelleMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="schaltschwelleMax" ref="schaltschwelleMax" :min="0" :max="100"
                                            v-model="getDiagDataCfg('schaltschwelleMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('schaltschwelleMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="schaltschwelleMax" class="mr-2">{{ getDiagDataCfg('schaltschwelleMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Motor-Einstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-heat bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="spaltZeroPoint" ref="spaltZeroPoint" :min="0" :max="100"
                                            v-model="getDiagDataCfg('spaltZeroPoint').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('spaltZeroPoint').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="spaltZeroPoint" class="mr-2">{{ getDiagDataCfg('spaltZeroPoint').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="stellungDeadZone" ref="stellungDeadZone" :min="0" :max="100"
                                            v-model="getDiagDataCfg('stellungDeadZone').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('stellungDeadZone').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="stellungDeadZone" class="mr-2">{{ getDiagDataCfg('stellungDeadZone').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="radiationFilterTime" ref="radiationFilterTime" :min="0" :max="1000"
                                            v-model="getDiagDataCfg('radiationFilterTime').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('radiationFilterTime').unit}`"
                                            :minFractionDigits="3" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="radiationFilterTime" class="mr-2">{{ getDiagDataCfg('radiationFilterTime').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="modeDelay" ref="modeDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('modeDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="modeDelay" class="mr-2">Modus-Verzögerung (Umschaltung)</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <Dropdown v-model="this.getDiagDataCfg('reglerart').value" :options="getVentsControlType" optionLabel="label" optionValue="value" :placeholder="getDiagDataCfg('reglerart').label" class="w-full md:w-14rem"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen - Wahlschalter" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-alarm-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="alarmManualSwitchDelay" ref="alarmManualSwitchDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('alarmManualSwitchDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="alarmManualSwitchDelay" class="mr-2">{{ this.getDiagDataCfg('alarmManualSwitchDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmManualSwitchPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Alarmausgang" v-if="isMaster">
                        <Panel header="Zuordnung des Alarmausgangs" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-bell bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid mb-3">
                                <div class="field-radiobutton col justify-content-center">
                                    <RadioButton id="setAlarm0" name="setMode" :value="0" v-model="this.getDiagDataCfg('alarmAssignedOutput').value" />
                                    <label for="setAlarm0" class="mr-2">AL0</label>
                                    <RadioButton id="setAlarm1" name="setMode" :value="1" v-model="this.getDiagDataCfg('alarmAssignedOutput').value" />
                                    <label for="setAlarm1" class="mr-2">AL1</label>
                                    <RadioButton id="setAlarm2" name="setMode" :value="2" v-model="this.getDiagDataCfg('alarmAssignedOutput').value" />
                                    <label for="setAlarm2" class="mr-2">AL2</label>
                                    <RadioButton id="setAlarm3" name="setMode" :value="3" v-model="this.getDiagDataCfg('alarmAssignedOutput').value" />
                                    <label for="setAlarm3" class="mr-2">AL3</label>
                                    <RadioButton id="setAlarm4" name="setMode" :value="4" v-model="this.getDiagDataCfg('alarmAssignedOutput').value" />
                                    <label for="setAlarm4" class="mr-2">AL4</label>
                                    <RadioButton id="setAlarm5" name="setMode" :value="5" v-model="this.getDiagDataCfg('alarmAssignedOutput').value" />
                                    <label for="setAlarm5">AL5</label>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleMisc" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('misc')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <OverlayPanel
            class="param-overlay"
            ref="alarmOverlay"
            appendTo="body"
            :autoZIndex="true"
            @show="focusElement" 
            :dismissable="true"
            :showCloseIcon="true"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: 'auto' }"
        >
            <div v-for="(element) in this.alarmList" :key="element.key2">
                <span>🔴 {{ element.value }}</span>
            </div>
        </OverlayPanel>
        <div class="menu-overlay">
            <Button v-if="editMode" icon="pi pi-bars"
                class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber handle" title="Bewegen"></Button>
            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber" title="Menü"
                @click="toggle"></Button>
            <Menu :id="'boxMenu_' + 1" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
        </div>
        <div v-if="alarmStatus > 0" class="alarm-overlay">
            <div class="alarmticker">
                <span>{{ alarmTicker }}</span>
            </div>
        </div>
        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <div :key="alarmStatus" v-show="true" class="hidden md:inline-flex social-icon" v-badge="alarmQueue" @click="toogleAlarmOverlay">
                        <i :class="`${icon} fs-xxlarge icon-md`"></i>
                    </div>
                    <div class="flex mt-1 ml-2">
                        <widget-mini-progress label="geschlossen" :value="getModuleValue('OUT_Stellung_Aktuell')"></widget-mini-progress>
                    </div>
                    <div class="flex mt-1 ml-2">
                        <widget-mini-progress label="öffnen" :value="getModuleValue('OUT_OpenStep')" :max="getModuleValue('CFG.energyOpenStepCount').value"></widget-mini-progress>
                    </div>
                    <div class="flex mt-1 ml-2">
                        <widget-mini-progress label="schließen" :value="getModuleValue('OUT_CloseStep')" :max="getModuleValue('CFG.energyCloseStepCount').value"></widget-mini-progress>
                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="this.getModuleValue('OUT_Stellung_Aktuell')" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <!-- <div class="left flex flex-column"> -->
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :title="'Stellung Ist/Soll'" :value1="this.getModuleValue('OUT_Stellung_Aktuell')" :value2="this.getModuleValue('OUT_Stellung_Soll')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Energie Raum/Außentemperatur'" :value1="this.getModuleValue('OUT_StateEnergyRt')" :value2="this.getModuleValue('OUT_StateEnergyAt')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Energie Strahlung/Wärmestrahlung'" :value1="this.getModuleValue('OUT_StateEnergyRad')" :value2="this.getModuleValue('OUT_StateEnergyIr')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Schattieren'" :value1="this.getModuleValue('OUT_StateShading')" :value2="this.getModuleValue('OUT_ShadingRadiation')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Zeit Schießen/Öffnen'" :value1="this.getModuleValue('OUT_ShadingCloseTime')" :value2="this.getModuleValue('OUT_ShadingOpenTime')"></Widget_rowValues2>
                    </ul>
                </div>
                <div class="right flex flex-column">
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Energiesparen" subtitle="Schutz vor Kälte und Auskühlen" icon="fi fi-rr-cloud-snow" fgColor="yellow" bgColor="brown"
                                @click="toggleOP($event, 'energy')" >
                            </button-enat>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Schattieren" subtitle="Schutz vor zu hoher Sonnenstrahlung" icon="fi fi-rr-cloud-sun" fgColor="yellow" bgColor="brown"
                                @click="toggleOP($event, 'shading')" >
                            </button-enat>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="MIN/MAX" subtitle="Begrenzungen und Stellungen" icon="fi fi-rr-settings-sliders" fgColor="yellow" bgColor="brown"
                                @click="toggleOP($event, 'minmax')" >
                            </button-enat>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Allgemein" subtitle="Allgemeine Einstellungen" icon="fi fi-rr-settings" fgColor="yellow" bgColor="darkSteel"
                                @click="toggleOP($event, 'misc')" >
                            </button-enat>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-2">
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="Status" :value="getModuleValue('OUT_CurrentModeStr').value" :moduleData="moduleData" icon="fi fi-rr-pulse" />
                        </div>
                    </div>
                </div>
            </div>
            <textbox-enat title="Status" :value="currentStatusStr" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
        </div>
        <Dialog 
            v-model:visible="showDiagName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Bezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideNameDiag" @keydown.enter="saveNameDiag(changeDiagName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="periodName" ref="periodName" v-model="changeDiagName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="periodName" class="mr-2">Bezeichnung</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveNameDiag(changeDiagName)" />
            </template>
        </Dialog>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('OUT_Stellung_Aktuell').icon" :node="getModuleValue('OUT_Stellung_Aktuell')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import widgetMiniProgress from '@/components/fragments/widgetMiniProgress.vue';
// import widgetTimeSet from '@/components/fragments/widgetTimeSet.vue';

export default defineComponent({
    name: 'widgetScreenController',
    setup() { },
    components: {
        Widget_rowValues2,
        dialogChartSingle,
        widgetMiniProgress,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        // nodeEntry: {
        //     handler: function(entry) {
        //         // this.filterParameters();
        //         // if (Array.isArray(entries) && entries.length > 0) {
        //         //     this.loading = false;
        //         // } else {
        //         //     this.loading = true;
        //         // }
        //     },
        //     deep: true
        // }
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            getVentsControlType: 'types/getVentsControlType',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else if (this.moduleData !== null && this.moduleData.metadata.label) {
                return this.moduleData.metadata.label;
            } else {
                return store.getters.getNoval;
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                let background = `bg-${this.content.style.background}`;
                if (this.alarmStatus > 0) background = 'alarm';
                return `box ptrn-${this.content.style.pattern} ${background} fg-${this.content.style.foreground}`;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer bg-lightCrimson`;
            }
        },
        alarmEntriesCount: function () {
            if (this.getModuleValue(`AlarmEntriesCount`).value > 0) {
                return this.getModuleValue(`AlarmEntriesCount`).value;
            } else {
                return 0;
            }
        },
        alarmStatus: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value <= 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmQueue: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value === 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmList: function () {
            let activeAlarms = [];
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value !== 0) {
                    activeAlarms.push(this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`));
                }
            }
            return activeAlarms;
        },
        alarmTicker: function () {
            let alarmStr = '';
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value > 0) {
                    alarmStr += `🔴 ${this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value} `;
                }
            }
            return alarmStr;
        },
        orientationStr1: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 1);
        },
        orientationStr2: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 2);
        },
        currentStatusStr: function () {
            return this.getModuleValue('OUT_StatusStr').value;
        },
        currentPositionFloat1: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_1_Aktuell').value);
        },
        currentPositionFloat2: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_2_Aktuell').value);
        },
        periodIndex: function () {
            return this.getModuleValue('OUT_AktPeriodeHzg.PeriodIndex').value;
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            saveWait: false,
            dialogLoading: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            nodeData: [],
            nodeDataOld: [],
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            nodeDataMinMax: [],
            nodeDataMinMaxOld: [],
            nodeDataEnergy: [],
            nodeDataEnergyOld: [],
            nodeDataShading: [],
            nodeDataShadingOld: [],
            nodeDataPeriods: [],
            nodeDataPeriodsOld: [],
            showDiagName: false,
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            lala: null,
            showDiagEnergy: false,
            showDiagShading: false,
            showDiagMisc: false,
            showDiagMinMax: false,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Energiesparen',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'energy');
                    },
                },
                {
                    label: 'Schattieren',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'shading');
                    },
                },
                {
                    label: 'Min/Max',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'minmax');
                    },
                },
                {
                    label: 'Einstellungen',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editValve(this.content);
                        this.toggleOP(null, 'misc');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        getDiagData(key) {
            if (this.nodeData.length > 0) {
                const result = this.nodeData.find(entry => entry.key2 === key);
                return result;
            }
            return null;
        },
        getDiagDataPeriods(index, displayName) {
            if (this.nodeDataPeriods.length > 0 && index >= 0) {
                const result = this.nodeDataPeriods[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataEnergy(displayName) {
            if (this.nodeDataEnergy.length > 0 && displayName) {
                const result = this.nodeDataEnergy.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataShading(displayName) {
            if (this.nodeDataShading.length > 0 && displayName) {
                const result = this.nodeDataShading.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataMinMax(displayName) {
            if (this.nodeDataMinMax.length > 0 && displayName) {
                const result = this.nodeDataMinMax.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        clearVals() {
            this.nodeDataCfg = [];
            this.nodeDataCfgOld = [];
            this.nodeDataPeriods = [];
            this.nodeDataPeriodsOld = [];
            this.saveWait = false;
            this.dialogLoading = false;
        },
        updateMap() {
            // this.node = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    this.node.set(key, {...child, value: child.value});
                }
            }
        },
        currentMap() {
            let map = new Map();
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    map.set(key, {...child, value: child.value});
                }
                return map;
            }
            return null;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        returnChild(childStr) {
            if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
                return helpers.returnChild(this.nodeEntry, childStr);
            } else {
                return null;
            }
        },
        returnParameter(node, key, parameter) {
            return helpers.returnParameter(node, key, parameter);
        },
        returnParameterValue(node, key, parameter, unit) {
            return helpers.returnParameterValue(node, key, parameter, unit);
        },
        timeDialog(time) {
            console.log(time);
        },
        toogleAlarmOverlay(event) {
            this.$refs.alarmOverlay.toggle(event);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.this.getModuleValueStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 'energy') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataEnergy = JSON.parse(JSON.stringify(response));
                            this.nodeDataEnergyOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataEnergy);
                            console.log(this.nodeDataEnergyOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagEnergy = true;
                }); 
            } else if (grp === 'shading') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataShading = JSON.parse(JSON.stringify(response));
                            this.nodeDataShadingOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataShading);
                            console.log(this.nodeDataShadingOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagShading = true;
                }); 
            } else if (grp === 'minmax') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataMinMax = JSON.parse(JSON.stringify(response));
                            this.nodeDataMinMaxOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataMinMax);
                            console.log(this.nodeDataMinMaxOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMinMax = true;
                }); 
            } else if (grp === 'misc') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataCfg = JSON.parse(JSON.stringify(response));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMisc = true;
                }); 
            }
        },
        diagToggleEnergy() {
            if (this.showDiagEnergy) {
                this.showDiagEnergy = false;
            } else {
                this.showDiagEnergy = true;
            }
            this.hideOP();
        },
        diagToggleShading() {
            if (this.showDiagShading) {
                this.showDiagShading = false;
            } else {
                this.showDiagShading = true;
            }
            this.hideOP();
        },
        diagToggleMinMax() {
            if (this.showDiagMinMax) {
                this.showDiagMinMax = false;
            } else {
                this.showDiagMinMax = true;
            }
            this.hideOP();
        },
        diagToggleMisc() {
            if (this.showDiagMisc) {
                this.showDiagMisc = false;
            } else {
                this.showDiagMisc = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'energy') {
                    console.log(this.nodeDataEnergy);
                    console.log(this.nodeDataEnergyOld);
                    const flatHzg = this.nodeDataEnergy.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataEnergyOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'shading') {
                    console.log(this.nodeDataShading);
                    console.log(this.nodeDataShadingOld);
                    const flatHzg = this.nodeDataShading.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataShadingOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'minmax') {
                    console.log(this.nodeDataMinMax);
                    console.log(this.nodeDataMinMaxOld);
                    const flatHzg = this.nodeDataMinMax.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataMinMaxOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'misc') {
                    console.log(this.nodeDataCfg);
                    console.log(this.nodeDataCfgOld);
                    this.nodeDataCfg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = this.nodeDataCfgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                }
                
                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'energy') this.showDiagEnergy = false;
                            if (diag === 'shading') this.showDiagShading = false;
                            if (diag === 'minmax') this.showDiagMinMax = false;
                            if (diag === 'misc') this.showDiagMisc = false;
                            this.hideOP();
                        }
                    });
                } else {
                    setOpcValueToast(null, this.$root);
                    this.dialogLoading = false;
                    if (diag === 'energy') this.showDiagEnergy = false;
                    if (diag === 'shading') this.showDiagShading = false;
                    if (diag === 'minmax') this.showDiagMinMax = false;
                    if (diag === 'misc') this.showDiagMisc = false;
                    this.hideOP();
                }
            }
        },
        toogleNameDiag(name) {
            this.changeDiagName = JSON.parse(JSON.stringify(name));
            this.showDiagName = true;
        },
        hideNameDiag() {
            this.changeDiagName = null;
            this.showDiagName = false;
        },
        saveNameDiag(node) {
            console.log(node);
            if (node.child0) {
                const child = JSON.parse(node.child0);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPeriods[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagName.value === 'string' && this.changeDiagName.value.length > 0) {
                            obj2.value = this.changeDiagName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideNameDiag();
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        showChart(value) {
            if (value === 'value1') this.showChart1 = true;
        },
        calcLux(val, mode) {
            return helpers.calcLight(val, mode);
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}

.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}

.fade-in-enter-active {
    animation: fadeIn linear 2s;
}

.fade-in-leave-active {
    animation: fadeOut linear 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        // opacity: 0;
    }
    50% {
        // opacity: 1;
        background-color: rgba(117, 18, 0, 0.5);
    }
    100% {
        // opacity: 0;
    }
}

@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}

.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}

.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
}

.card:hover .menu-overlay {
    opacity: 1;
}

.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}

// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.p-togglebutton {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.2rem;
    margin: 0;
}
</style>